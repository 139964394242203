import {
  type MessageInsert,
  type ThreadMessage,
  ThreadMessageKinds,
  isMessageKnowledgeV1,
  isMessagePrompt,
  isMessageTextV1,
  isThreadInsert,
  type q,
  type threads,
} from "@repo/client";
import type { RouteSectionProps } from "@solidjs/router";
import { type Component, Match, Suspense, Switch, createEffect, createMemo, createSignal, on } from "solid-js";
import { PersistentPrompt } from "~/domains/chat/prompt/Prompt";
import { PromptContextProvider } from "~/domains/chat/prompt/PromptContext";
import type { CampaingPageDataPrompt } from "~/domains/marketing/useUseCasesData";
import { ThreadPanel } from "~/domains/threads/components/panels/ThreadPanel/ThreadPanel";
import { useWire } from "~/wire";

export type ThreadGroup = { type: "group"; content: ThreadMessage[] };
export type ThreadUnit = {
  type: "unit";
  content: threads.MessageKnowledgeV1 | MessageInsert;
};
export type UIManagedAsset = {
  assetId: string;
  name: string;
  active: boolean;
  justChanged: boolean;
};

import { SEOHeaders } from "~/components/SEOHeaders";
import {
  AppLayout,
  AppLayoutLeftSidebar,
  AppLayoutMainContent,
  AppLayoutRightSidebar,
  AppLayoutTopbarMain,
} from "~/components/layouts/AppLayout/AppLayout";
import { ThreadTabs } from "../components/ThreadTabs";
import { AssetsSidebar, TreeNavSidebar } from "../components/TreeNavSidebar";
import { KnowledgePanel } from "../components/panels/KnowledgePanel";
import { KnowledgePanelModal } from "../components/panels/KnowledgePanel/KnowledgePanelModal";
import { useAutoSubmitFileCampaign } from "../components/useAutoSubmitFileCampaign";

const mapThreadMessagesToUIData = (deps: {
  messages: ThreadMessage[];
  threadId: string | null;
  updateThreadLabel: (threadId: string, label: string) => void;
}) => {
  const messages = deps.messages;
  const grouped: (ThreadGroup | ThreadUnit)[] = [];
  let last: ThreadGroup | ThreadUnit = { type: "group", content: [] };

  for (const m of messages) {
    if (isMessagePrompt(m)) {
      last = { type: "group", content: [m] };
      grouped.push(last);
      continue;
    }

    if (isMessageKnowledgeV1(m)) {
      if (last.type === "unit" && isMessageKnowledgeV1(last.content)) {
        last.content = mergeKnowledgeChange(last.content, m);
      } else {
        last = { type: "unit", content: m };
        grouped.push(last);
      }
      continue;
    }

    if (isThreadInsert(m)) {
      last = { type: "unit", content: m };
      grouped.push(last);
    }

    if (isMessageTextV1(m)) {
      if (m.isDone && m.label) {
        if (deps.threadId) deps.updateThreadLabel(deps.threadId, m.label);
      }
    }
    if (m.kind === ThreadMessageKinds.MessageKindRenameThreadV1) continue;
    // if (
    //   isContentSuggestedActions(m.content) &&
    //   index !== filtered.length - 1
    // ) {
    //   return;
    // }

    if (last.type === "unit") {
      last = { type: "group", content: [] };
    }
    last.content.push(m);
  }

  return grouped;
};

export const ThreadScreen: Component<{ prompt?: CampaingPageDataPrompt } & RouteSectionProps<unknown>> = (props) => {
  const wire = useWire();
  const threadsService = wire.services.threads;
  const identityService = wire.services.identity;
  const [tab, setTab] = createSignal<string | undefined>();
  const [showKnowledge, setShowKnowledge] = createSignal(false);
  const [threads, { updateThreadLabel }] = wire.services.threads.threadsListResource;
  const [assets] = wire.services.knowledge.assetsListResource;

  // Post-processing messages and grouping them in blocks
  // Using the text instruction unit as the start of a next block
  const messages = createMemo(() =>
    mapThreadMessagesToUIData({
      messages: wire.services.threads.messages(),
      threadId: wire.services.threads.snapshot.context.threadId,
      updateThreadLabel,
    }),
  );

  const isNewThreadId = createMemo(() => props.params.thread_id === "new");

  createEffect(
    on(
      () => isNewThreadId(),
      (isNew) => {
        if (isNew) {
          wire.services.limiting.guest.resetLastInteraction();
          threadsService.send(threadsService.eventFactory.newResetEvent());
        }
      },
    ),
  );

  createEffect(() => {
    if (!identityService.snapshot.context.identity.isAuthenticated) return;
    if (isNewThreadId()) return;

    const threadId = threadsService.snapshot?.context?.threadId;
    const threadIdURL = props.params.thread_id;
    if (threadId !== threadIdURL && threadIdURL) {
      wire.services.limiting.guest.resetLastInteraction();
      threadsService.send(threadsService.eventFactory.newResumeThreadEvent(threadIdURL));
    }
  });

  return (
    <PromptContextProvider
      initialPrompt={props.prompt?.prompt.name ? { content: props.prompt.prompt.name, highlight: true } : undefined}
      transformationID={props.prompt?.prompt.id}
      autoFocusInput
      positioning="sticky bottom-0"
    >
      <SEOHeaders title={props.prompt?.prompt.name} description={props.prompt?.prompt.summary} />

      <AppLayout
        topBarMain={
          <AppLayoutTopbarMain class="overflow-x-auto">
            <ThreadTabs tab={tab()} setTab={setTab} />
          </AppLayoutTopbarMain>
        }
        leftSidebar={
          <AppLayoutLeftSidebar>
            <TreeNavSidebar
              assets={assets()}
              threads={threads()}
              showKnowledge={showKnowledge()}
              setShowKnowledge={setShowKnowledge}
            />
          </AppLayoutLeftSidebar>
        }
        rightSidebar={
          <AppLayoutRightSidebar>
            <Suspense>
              <AssetsSidebar assets={assets()} setShowKnowledge={setShowKnowledge} />
            </Suspense>
          </AppLayoutRightSidebar>
        }
        mainContent={
          <AppLayoutMainContent class="overflow-y-auto">
            <Switch>
              <Match when={tab() === "SmartChat" || tab() === "Knowledge"}>
                <ThreadPanel isNewThreadId={isNewThreadId()} messages={messages()} prompt={props.prompt} />
                <PersistentPrompt showEnterpriseGrade />
              </Match>
            </Switch>
          </AppLayoutMainContent>
        }
      >
        <AutoSubmit />

        <KnowledgePanelModal
          show={showKnowledge()}
          onHide={() => {
            setShowKnowledge(false);
          }}
        >
          <KnowledgePanel
            showPowering
            setShowKnowledge={setShowKnowledge}
            title={wire.services.threads.snapshot.context.label || "New Thread"}
            class="md:px-8 py-8"
          />
        </KnowledgePanelModal>
      </AppLayout>
    </PromptContextProvider>
  );
};

// Needed as a component so that it can be inside the prompt context provider
const AutoSubmit = () => {
  useAutoSubmitFileCampaign();
  return undefined;
};

const sortStrings = (a: string, b: string) => a.localeCompare(b);
const sortAssets = (a: q.ControlplaneSsAsset, b: q.ControlplaneSsAsset) =>
  sortStrings(a.displayName || a.originalFilename, b.displayName || b.originalFilename);

const mergeKnowledgeChange = (
  m1: threads.MessageKnowledgeV1,
  m2: threads.MessageKnowledgeV1,
): threads.MessageKnowledgeV1 => {
  const mapAdded: Record<string, q.ControlplaneSsAsset> = {};
  const mapRemoved: Record<string, q.ControlplaneSsAsset> = {};

  m1.knowledgeFull.assetContext.added.forEach((a) => {
    mapAdded[a.id] = a;
  });
  m2.knowledgeFull.assetContext.added.forEach((a) => {
    mapAdded[a.id] = a;
  });
  m1.knowledgeFull.assetContext.removed.forEach((a) => {
    mapRemoved[a.id] = a;
  });
  m2.knowledgeFull.assetContext.removed.forEach((a) => {
    mapRemoved[a.id] = a;
  });

  Object.keys(mapAdded).forEach((id) => {
    if (mapRemoved[id]) {
      delete mapAdded[id];
      delete mapRemoved[id];
    }
  });

  const sortedAdded = Object.values(mapAdded).sort(sortAssets);
  const sortedRemoved = Object.values(mapRemoved).sort(sortAssets);
  return {
    ...m2,
    knowledge: {
      ...m2.knowledge,
      assetContext: {
        added: sortedAdded.map((a) => a.id),
        removed: sortedRemoved.map((a) => a.id),
      },
    },
    knowledgeFull: {
      ...m2.knowledgeFull,
      assetContext: {
        added: sortedAdded,
        removed: sortedRemoved,
      },
    },
  };
};
