import hljs from "highlight.js";
import remarkGfm from "remark-gfm";
import { type Component, type JSX, Match, Switch, onMount } from "solid-js";
import type { DOMElement } from "solid-js/jsx-runtime";
import { SolidMarkdown } from "solid-markdown";
import { twMerge } from "tailwind-merge";
import { CopyButton } from "~/components/buttons/copy/CopyButton";

const getDayOfWeek = () => {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  return days[new Date().getDay()] as string;
};
const getTimeOfDay = () => {
  const hour = new Date().getHours();

  if (hour >= 5 && hour < 12) {
    return "morning";
  }
  if (hour >= 12 && hour < 17) {
    return "afternoon";
  }
  if (hour >= 17 && hour < 21) {
    return "evening";
  }
  return "afternoon";
};

export const MarkdownRenderer: Component<{
  md: string;
  prefix: string;
  onMouseOverChild?: (
    e: MouseEvent & {
      currentTarget: HTMLElement;
      target: DOMElement;
    },
  ) => void;
  onMouseOutChild?: (
    e: MouseEvent & {
      currentTarget: HTMLElement;
      target: DOMElement;
    },
  ) => void;
}> = (props) => {
  const getKey = (index = "") => {
    return `${props.prefix};${index}`;
  };

  const selectableProps = (): JSX.HTMLAttributes<HTMLElement> => {
    return {
      //@ts-expect-error
      "data-selectable": true,
      onMouseOver: (e) => {
        props.onMouseOverChild?.(e);
      },
      onMouseOut: (e) => {
        props.onMouseOutChild?.(e);
      },
    };
  };

  const md = () =>
    props.md
      .replaceAll("${{CurrentDayOfWeek}}", getDayOfWeek())
      .replaceAll("${{CurrentTimeOfDayGreeting}}", getTimeOfDay());
  return (
    <SolidMarkdown
      remarkPlugins={[remarkGfm]}
      class="leading-relaxed"
      // Rendering dom elements as custom components to have more control over classes / extra behaviour (in the future)
      components={{
        hr: () => <hr class="my-4" />,
        h1: (props) => (
          <h1
            data-block={getKey((props as unknown as { key: string }).key)}
            class="text-4xl font-semibold mb-4 mt-6 scroll-mt-32 break-words"
            // biome-ignore lint/a11y/noNoninteractiveTabindex: <explanation>
            tabIndex={0}
            {...selectableProps}
          >
            {props.children}
          </h1>
        ),
        h2: (props) => (
          <h2
            data-block={getKey((props as unknown as { key: string }).key)}
            class="text-3xl font-semibold mb-4 mt-6 scroll-mt-32 break-words"
            // biome-ignore lint/a11y/noNoninteractiveTabindex: <explanation>
            tabIndex={0}
            {...selectableProps}
          >
            {props.children}
          </h2>
        ),
        h3: (props) => (
          <h3
            data-block={getKey((props as unknown as { key: string }).key)}
            class="text-2xl font-semibold mb-4 mt-6 scroll-mt-32 break-words"
            // biome-ignore lint/a11y/noNoninteractiveTabindex: <explanation>
            tabIndex={0}
            {...selectableProps}
          >
            {props.children}
          </h3>
        ),
        p: (props) => (
          <p
            data-block={getKey((props as unknown as { key: string }).key)}
            class="mb-2 scroll-mt-32 break-words"
            // biome-ignore lint/a11y/noNoninteractiveTabindex: <explanation>
            tabIndex={0}
            {...selectableProps}
          >
            {props.children}
          </p>
        ),
        ol: (props) => (
          <ol
            data-block={getKey((props as unknown as { key: string }).key)}
            start={props.start}
            class="list-outside list-decimal ml-4 sm:ml-6 mt-1 mb-4 scroll-mt-32 break-words"
          >
            {props.children}
          </ol>
        ),
        ul: (props) => (
          <ul
            data-block={getKey((props as unknown as { key: string }).key)}
            class="list-outside list-disc ml-4 sm:ml-6 mt-1 mb-4 scroll-mt-32 break-words"
          >
            {props.children}
          </ul>
        ),
        li: (props) => (
          <li
            class="ml-2 mt-2 scroll-mt-32 break-words"
            // biome-ignore lint/a11y/noNoninteractiveTabindex: <explanation>
            tabIndex={0}
            {...selectableProps}
            data-block={getKey((props as unknown as { key: string }).key)}
          >
            {props.children}
          </li>
        ),
        img: (props) => (
          <Switch
            fallback={
              <div data-block={getKey((props as unknown as { key: string }).key)} class="flex justify-center my-4">
                <img src={props.src} alt={props.alt} />
              </div>
            }
          >
            <Match when={props.src?.includes("example.com") || props.src?.includes("placeholder.com")}>
              <div
                data-block={getKey((props as unknown as { key: string }).key)}
                class="my-8 p-4 max-w-48 mx-auto border-2 border-black dark:border-slate-200"
              >
                <svg class="fill-black dark:fill-slate-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                  <title>Image not available</title>
                  <desc>Icon of a landscape with supporting text "Example"</desc>
                  <path d="M14.857,40.368c-2.525,0-4.579-2.033-4.579-4.532V11.093c0-2.499,2.054-4.532,4.579-4.532h34.366c2.525,0,4.579,2.033,4.579,4.532v24.742c0,2.499-2.054,4.532-4.579,4.532H14.857ZM25.795,20.666c-.869,0-1.701.333-2.283.915l-10.322,10.209v4.047c0,.91.748,1.651,1.668,1.651h34.366c.92,0,1.668-.74,1.668-1.651v-4.043l-5.632-5.577c-.604-.601-1.415-.932-2.282-.932s-1.681.332-2.291.935l-1.9,1.884,2.095,2.073c.563.561.562,1.476,0,2.039-.275.271-.641.421-1.031.421s-.756-.15-1.032-.423l-10.743-10.633c-.58-.58-1.412-.914-2.281-.914ZM14.857,9.443c-.92,0-1.668.74-1.668,1.65v16.626l8.266-8.178c1.101-1.093,2.681-1.721,4.338-1.721s3.241.627,4.346,1.721l6.587,6.521,1.902-1.884c1.158-1.144,2.703-1.775,4.348-1.775s3.185.63,4.341,1.772l3.574,3.537V11.093c0-.91-.748-1.65-1.668-1.65H14.857Z" />
                  <path d="M36.904,18.447c-1.233,0-2.236-.994-2.236-2.215s1.003-2.212,2.236-2.212,2.236.992,2.236,2.212-1.003,2.215-2.236,2.215Z" />
                  <path d="M10.04,47.471h4.842v.898h-3.772v2.607h3.183v.895h-3.183v3.025h3.902v.895h-4.972v-8.319Z" />
                  <path d="M17.45,52.576l-1.884-2.945h1.142l.834,1.35c.19.339.402.688.61,1.026h.05c.187-.339.375-.688.562-1.026l.762-1.35h1.097l-1.884,3.054,2.034,3.106h-1.142l-.917-1.432c-.215-.371-.445-.752-.677-1.105h-.05c-.215.354-.417.73-.63,1.105l-.844,1.432h-1.097l2.034-3.215Z" />
                  <path d="M21.477,54.19c0-1.34,1.169-2.013,3.822-2.297-.002-.794-.267-1.558-1.284-1.558-.72,0-1.369.339-1.856.663l-.412-.715c.572-.373,1.447-.806,2.443-.806,1.519,0,2.164.999,2.164,2.535v3.779h-.872l-.087-.737h-.035c-.595.49-1.287.888-2.059.888-1.042,0-1.824-.638-1.824-1.751ZM25.299,54.287v-1.711c-2.086.25-2.791.754-2.791,1.543,0,.7.48.984,1.092.984.6,0,1.097-.289,1.699-.816Z" />
                  <path d="M28.307,49.63h.872l.087.893h.035c.545-.584,1.197-1.046,1.936-1.046.952,0,1.462.453,1.719,1.175.647-.692,1.297-1.175,2.054-1.175,1.279,0,1.894.838,1.894,2.409v3.905h-1.054v-3.769c0-1.147-.367-1.64-1.157-1.64-.485,0-.984.314-1.561.947v4.461h-1.052v-3.769c0-1.147-.367-1.64-1.159-1.64-.465,0-.984.314-1.559.947v4.461h-1.054v-6.16Z" />
                  <path d="M38.913,49.63h.872l.087.71h.035c.562-.462,1.264-.863,1.984-.863,1.609,0,2.468,1.237,2.468,3.141,0,2.1-1.274,3.324-2.696,3.324-.57,0-1.154-.26-1.724-.712l.027,1.076v2.085h-1.054v-8.759ZM43.272,52.625c0-1.358-.47-2.27-1.641-2.27-.527,0-1.059.287-1.664.841v3.23c.562.467,1.104.641,1.514.641,1.032,0,1.791-.92,1.791-2.441Z" />
                  <path d="M45.96,54.554v-7.792h1.054v7.867c0,.319.14.448.292.448.062,0,.112,0,.232-.027l.142.796c-.15.054-.33.096-.607.096-.789,0-1.114-.497-1.114-1.387Z" />
                  <path d="M48.758,52.719c0-2.006,1.367-3.242,2.798-3.242,1.589,0,2.483,1.13,2.483,2.891,0,.22-.017.44-.047.594h-4.192c.075,1.303.882,2.152,2.069,2.152.597,0,1.087-.193,1.549-.492l.377.683c-.547.354-1.209.638-2.056.638-1.659,0-2.98-1.199-2.98-3.222ZM53.111,52.215c0-1.234-.562-1.907-1.534-1.907-.877,0-1.659.695-1.786,1.907h3.32Z" />
                </svg>
              </div>
            </Match>
          </Switch>
        ),
        a: (props) => (
          <a
            data-block={getKey((props as unknown as { key: string }).key)}
            class="text-violet-500 dark:text-violet-400 underline underline-offset-2 scroll-mt-32 break-words"
            href={props.href}
            target="_blank"
            rel="noreferrer"
          >
            {props.children}
          </a>
        ),
        pre: (props) => {
          let ref!: HTMLPreElement;

          const languageClass = () => {
            if (Array.isArray(props.node.properties?.className)) {
              const className = props.node.properties.className
                .find((c) => c.toString().startsWith("language"))
                ?.toString();
              if (className) return className;
            }
            const code = props.node.children.find((c) => c.type === "element" && c.tagName === "code");
            if (code && "properties" in code && Array.isArray(code.properties?.className)) {
              const className = code.properties.className.find((c) => c.toString().startsWith("language"))?.toString();
              if (className) return className;
            }
          };
          onMount(() => {
            hljs.highlightElement(ref);
          });
          return (
            <div class="rounded border border-indigo-900 mb-4">
              <div class="rounded-t flex justify-end bg-indigo-1000 dark:bg-indigo-1100/70 pr-1 pt-1">
                <CopyButton
                  class="bg-white dark:bg-indigo-800"
                  content=""
                  label="Copy"
                  onClick={async () => {
                    await navigator.clipboard.writeText(ref.innerText);
                  }}
                />
              </div>
              <pre
                data-block={getKey((props as unknown as { key: string }).key)}
                ref={ref}
                class={twMerge("overflow-auto p-4 rounded-b scroll-mt-32", props.class, languageClass() || false)}
              >
                <code>{props.children}</code>
              </pre>
            </div>
          );
        },
        table: (props) => {
          return (
            <div
              data-block={getKey((props as unknown as { key: string }).key)}
              class="max-w-full overflow-x-auto border border-slate-100 dark:border-indigo-900 rounded bg-white/30 dark:text-white dark:bg-indigo-1100/70 mb-6 scroll-mt-32"
            >
              <table class="w-full table-automin-w-full divide-y divide-slate-100 dark:divide-indigo-900">
                {props.children}
              </table>
            </div>
          );
        },
        th: (props) => (
          <th
            data-block={getKey((props as unknown as { key: string }).key)}
            class="p-2 text-left text-sm font-semibold text-slate-900 dark:text-white scroll-mt-32"
          >
            {props.children}
          </th>
        ),
        tbody: (props) => (
          <tbody
            data-block={getKey((props as unknown as { key: string }).key)}
            class="divide-y divide-slate-100 dark:divide-indigo-900 scroll-mt-32"
          >
            {props.children}
          </tbody>
        ),
        td: (props) => (
          <td
            data-block={getKey((props as unknown as { key: string }).key)}
            class="whitespace-nowrap px-3 py-4 text-sm text-slate-800 dark:text-indigo-100 scroll-mt-32"
          >
            {props.children}
          </td>
        ),
      }}
    >
      {md()}
    </SolidMarkdown>
  );
};
